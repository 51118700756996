
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
// @import "variables";

// Bootstrap
// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "device-mockups";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/_variables";
@import "node_modules/bootstrap-sass/assets/stylesheets/_bootstrap";
@import "node_modules/eonasdan-bootstrap-datetimepicker/src/sass/bootstrap-datetimepicker-build";

// .device-wrapper {
//   background-color: 'red';
// }

html, body, #app {
  height: 100%;
}

.padded-button-wrapper {
  @media(max-width:992px){
    padding-bottom: 40px;
    text-align: center;
  }
}


.help-block {
  font-style: italic;
  font-size: 0.9em;
}

[v-cloak] { display: none; }
